.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form {
  width: inherit;
  /* margin: auto; */
}


.btn_top:hover {
  --tw-bg-opacity: 1;
  /* background-color: red; */
  background-color: whitesmoke;
}

.bg-black {
  background-color: #1e2028;
}

.bg-white {
  background-color: #fafbf6;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  /* Ensure modal is above overlay */
}

.direction {
  direction: RTL
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul.options {
  display: block;
  list-style: none;
  text-align: left;
  position: absolute;
}

ul.options li {
  font-weight: 500;
  padding-left: 10px;
  display: block;
  color: black;
  background: white;
  margin: auto;
  font-size: 1.0rem;
  border-radius: 2px;
  box-shadow: 5px 5px 5px #888888;
}


ul.options li:hover {
  color: blue;
  cursor: pointer;
  transition: 0.3s all;
  background-color: lightcyan;
}

ul.options li.option-active {
  background: whitesmoke;
  color: #00b4cc;
}

.no-options {
  color: white;
}

.invoiceContainer2 {
  margin: 10px;

}

.invoiceContainer {
  margin: 10px;
  height: 450px;
  width: 95%;
  overflow-y: scroll;
  box-shadow: 3px 5px 8px 2px #222;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 480px) {
  .invoiceContainer {
    height: 366px;
    width: 95%;
    overflow-y: scroll;
  }

}

.invoiceContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invoiceContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#page {
  width: 850px;

}

@media screen and (max-width: 480px) {
  .font_size {
    font-size: 40%
  }
}

.rwd-table {
  margin: auto;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: 1px;
}

@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}

@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }

  .rwd-table td:before {
    display: none;
  }

  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }

  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }

  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }

  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}

.nav_links {
  font-size: medium !important;
}

.logo {
  height: 40px;
}

.title {
  font-size: 30px !important;
}

@media screen and (max-width: 1000px) {
  .nav_links {
    font-size: small !important;
  }

  .title {
    font-size: 20px !important;
  }

  .logo {
    height: 30px;
  }
}

@media screen and (max-width: 800px) {
  .nav_links {
    font-size: x-small !important;
  }

  .title {
    font-size: 15px !important;
  }

  .logo {
    height: 25px;
  }
}

@media screen and (max-width: 800px) {
  .nav_links {
    font-size: x-small !important;
  }

  .title {
    font-size: 15px !important;
  }

  .logo {
    height: 25px;
  }
}

/* THE END OF THE IMPORTANT STUFF */

/* Basic Styling */
/* body {
  background: #4B79A1;
  background: -webkit-linear-gradient(to left, #4B79A1, #283E51);
  background: linear-gradient(to left, #4B79A1, #283E51);
} */

.container {
  display: block;
  text-align: center;
}

.dropdown {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  column-gap: 5px;
}

@media screen and (max-width: 810px) {
  .dropdown {
    display: flex;
    justify-content: space-around;
    column-gap: 3px;
    font-size: x-small !important;
  }

  .labelselect {
    font-size: 11px !important;
  }

  .selectfonts {
    font-size: 10px !important;
  }

  .select {
    height: 31px;
    width: 120px;
  }

  .icons {
    height: 10px !important;
  }
}

@media screen and (max-width: 720px) {


  .labelselect {
    font-size: 8px !important;
  }

  .selectfonts {
    font-size: 8px !important;
  }

  .select {
    height: 31px;
    width: auto !important;
  }
}

@media screen and (max-width: 425px) {
  #demo-select-small-label {
    font-size: 90%;
  }

  .fromwidth {
    max-width: 80% !important;
  }

  .dropdown {
    display: grid;
    grid-template-columns: auto auto;
    gap: 2px;
    column-gap: 0px;
  }

  .select {
    height: 30px;
    width: 150px !important;
  }
}


@media screen and (min-width: 767px) {
  .on-full-screen {
    display: none;
  }
}

@media screen and (max-width: 810px) {

  .text_size {
    font-size: 8px !important;
    padding: 8px 6px;
  }
}

@media screen and (max-width: 500px) {

  .text_size {
    font-size: 6px !important;
    padding: 8px 4px;
  }
}

@media screen and (max-width: 767px) {
  .response {
    display: none;
  }



  .head th:nth-child(10) {
    display: none;
  }

  .head th:nth-child(11) {
    display: none;
  }

  .head th:nth-child(12) {
    display: none;
  }

  .head th:nth-child(9) {
    display: none;
  }

  .headTruck th:nth-child(5) {
    display: none;
  }

  .headTruck th:nth-child(6) {
    display: none;
  }

  .headTruck th:nth-child(7) {
    display: none;
  }

}

@media (max-width: 719px) {
  .nav-dd {
    position: absolute;
    top: 2.5rem;
    right: 0px;
    left: 0px;
    z-index: 30;

  }


}

@media (min-width: 767px) {
  .head th:first-child {
    display: none;
  }

  .headTruck th:first-child {
    display: none;
  }

}

/* Dashboard css */
/*
Basic
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.root {
  --color-main: #f3950d;
  --main-accent: #ffebcc;
  --bg: #f5eedc;
  --bg-2: #dce5ff;
  --main: #292c6d;
  --shadow: rgba(17, 17, 26, 0.1) 2px 0px 16px;
  width: 96%;
  margin: auto;
  margin-top: 50px;
}

* {
  font-family: "Poppins", sans-serif;
}

/* helper */
.text-danger {
  color: red;
}

.text-success {
  color: #2ec3a3;
}

.text-main {
  color: var(--color-main);
}

.analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2.5rem;
  margin-bottom: 3rem;
}

.analytic {
  box-shadow: var(--shadow);
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  padding-left: 2rem;
}

.analytic-info h4 {
  font-weight: 400;
  color: #555;
  font-size: 0.98rem;
}

.analytic-info h1 {
  color: var(--main-text);
  font-weight: 600;
}

.analytic-info h1 small {
  font-size: 0.8rem;
  font-weight: 700;
}

.analytic:first-child .analytic-icon {
  background-color: #dce5ff;
  color: #6883db;
}

.analytic:nth-child(2) .analytic-icon {
  background-color: #ebf7f5;
  color: red;
}

.analytic:nth-child(3) .analytic-icon {
  background-color: #ebf7f5;
  color: #2ec3a3;
}

.analytic:nth-child(4) .analytic-icon {
  background-color: var(--main-accent);
  color: var(--color-main);
}

.analytic-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-right: 0.8rem;
}

.section-head {
  font-size: 1.4rem;
  color: var(--main);
  font-weight: 600;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-self: flex-start;
}

@media only screen and (max-width: 860px) {
  .analytics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 580px) {
  .analytics {
    grid-template-columns: 100%;
  }
}









/* popup class */

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to the div */
.fade-up {
  animation: fadeUp 0.5s ease-in-out;
}

.arrow_box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* test uiverse */






/* <div className="m-auto w-96">
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Company
                            </label>
                            <input
                                type=""
                                id=""
                                value={company}
                                onChange={handleCompanyDropDown}
                                onFocus={() => setViewSuggestionCompany(true)}
                                className={`m-auto border border-gray-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-black dark:text-white`}
                                placeholder="Daraz"
                                ref={companyInputRef}
                                required
                            />
                            {
                                viewSuggestionCompany === true ?
                                    <ul className='pl-2 overflow-x-hidden absolute bg-white border-x-2 w-96 shadow-gray-500'>
                                        {
                                            companyData.filter((item) => {
                                                const TolowerCaseValue = company.toLowerCase()
                                                const compnayValue = item.name.toLowerCase()
                                                return compnayValue.startsWith(TolowerCaseValue) && item.name !== company
                                            }).map((item) =>
                                                <li key={item.id} className='bg-white hover:bg-blue-gray-100  p-0 text-left text-sm text-gray-600 font-normal li_suggestion' onClick={() => { setCompany(item.name); setViewSuggestionCompany(false) }}>{item.name}</li>
                                            )
                                        }
                                    </ul>
                                    :
                                    ''
                            } */
/* 
useEffect(() => {
  const handleClickOutsideto = (event) => {
      if (companyInputRef.current && !companyInputRef.current.contains(event.target)) {
          setViewSuggestionTo(false);
      }
  };
  document.addEventListener('click', handleClickOutsideto);
  return () => {
      document.removeEventListener('click', handleClickOutsideto);
  };
}, [companyInputRef]); */
/* const handlePdf = () => {
  const input = document.getElementById('page');

  html2canvas(input)
      .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'pt', 'a4');
          var width = pdf.internal.pageSize.getWidth();
          // var height = pdf.internal.pageSize.getHeight();
          pdf.addImage(imgData, 'JPEG', 0, 0, width, 0);
          pdf.save("test.pdf");
      });
}; */

/* ((new Date(dateFrom)) <= (new Date(item.date.split(",")[0])) && (new Date(item.date.split(",")[0])) <= (new Date(dateTo))) && */


/* mongoDB password */
/* FXxWjc6vCsyKH3Fn */